import React, {  useEffect, useState } from "react";
import { TableUtils, baseSearchObject, fieldTypes, sortDirections } from "../../shared/tables/TableUtils";
import { StarTable } from "../../shared/tables/StarTable";
import { getContactByLogin, saveInlineContactInfo, searchContactInfos } from "../../../actions";
import { Col, Row } from "react-bootstrap";

import { BtnFill } from "../../shared/BtnFill";
import { ContactInfoModal } from "./ContactInfoModal";
import { PRIORITIES } from "./PRIORITY";
import { formatDateTimeForDisplay } from "../../shared/Utility";
import { BwmSelect } from "../../shared/form/BwmSelect";
import { PopupError } from "../../shared/PopupError";
import { APPLICATION_GENERIC_ERROR } from "../../../config/Constants";

//TODO: implementare salvataggio e modifica contatti
export function StarTel() {
    const queryParameters = new URLSearchParams(window.location.search)
    const selectedLogin = queryParameters.get("login");
    const [rows, setRows] = useState([]);
    const [editedIds,setEditedIds] = useState([])
    const [searchObject, setSearchObject] = useState({
        ...baseSearchObject
        , pageSize: 50
        , denomination: ""
        , callPriority: []
        , login: ""
        , callDate: ""
        , caller: ""
        , calledContact: ""
        , callNotes: ""
        , version: 5
    });
    const [isContactModalOpen, setIsContactModalOpen] = useState(false);
    const [selectedContact, setSelectedContact] = useState(null);

    const [selectedContactNumber, setSelectedContactNumber] = useState(null);

    const opzioniChiamante = [{ id: "Silvana", description: "Silvana" }, { id: "Valentina", description: "Valentina" }];
    const [chiamante, setChiamante] = useState(opzioniChiamante[0]);

    const baseContact = {
        callPriority: "SOSPESO",
        contactNumbers: [],
        contactHistory: [],
        contactApplications: []
    };

    useEffect(()=>{
        if(selectedLogin){
            getContactByLogin(selectedLogin)
                .then((res)=>{
                    if(res){
                        if(!res.contactNumbers){
                            res.contactNumbers = [];
                        }
                        openContactModalAndAddNewHistory(res,  res.contactNumbers[0]);
                    } else {
                        openContactModal(baseContact);
                    }
                })
        }
    },[])


    const headers = [
        
        TableUtils.composeHeader({
            fieldName: "denomination",
            displayedName: "Ragione Sociale",
            maxWidth: "15rem"
        }),
        TableUtils.composeHeader({
            fieldName: "login",
            displayedName: "Utente",
            maxWidth: "15rem"
        }),
        TableUtils.composeHeader({
            fieldName: "calledContact",
            displayedName: "Contatto",
            maxWidth: "15rem"
        }),
        TableUtils.composeHeader({
            fieldName: "phoneNumbers",
            displayedName: "Telefono",
            maxWidth: "15rem"
        }),
        TableUtils.composeHeader({
            fieldName: "callDate",
            displayedName: "Ultima telefonata",
            minWidth: "10rem",
            maxWidth: "10rem"
        }),
        TableUtils.composeHeader({
            fieldName: "caller",
            displayedName: "Chiamato da",
            minWidth: "7rem",
            maxWidth: "7rem"
        }),
        TableUtils.composeHeader({
            fieldName: "callNotes",
            displayedName: "Note",
            minWidth: "20rem"
        }),
        TableUtils.composeHeader({
            fieldName: "callPriority",
            displayedName: "Priorità",
            type: fieldTypes.SELECTION,
            options: PRIORITIES,
            minWidth: "10rem",
            maxWidth: "10rem"
        }),
        TableUtils.composeHeader({
            fieldName: "save",
            displayedName: "Modifica",
            sortable: false,
            searchable: false,
            minWidth: "9rem",
            maxWidth: "9rem"
        }),
    ];

    const openContactModal = (contactInfo, contactNumber) => {
        setSelectedContact(contactInfo);
        setSelectedContactNumber(contactNumber || contactInfo.contactNumbers[0]);
        setIsContactModalOpen(true);

    }

    const openContactModalAndAddNewHistory = (contactInfo, contactNumber) => {
        openContactModal(contactInfo, contactNumber);

    }

    const closeContactModal = () => {
        setIsContactModalOpen(false);
        setSelectedContact(null);
        setSelectedContactNumber(null);
        getCustomerContacts();
    }

    const prepareRows = (customerContacts = []) => {
        return customerContacts.map(c => {
            return TableUtils.composeRow({
                id: c.id,
                cellData: {
                    login: TableUtils.composeCell({
                        fieldName: "login",
                        fieldValue: c.login,
                        component: () => {
                            return <div className=' d-flex align-items-center justify-content-between'>
                                <span className="text-truncate" style={{ maxWidth: "75%" }} onClick={() => openContactModal(c)}>{c.login}</span>
                                <div  className=' d-flex align-items-center'>
                                    {c.login && <button onClick={() => navigator.clipboard.writeText(c.login)} type="button" className="ms-1 btn btn-primary btn-new-rel btn-sm ">
                                        E
                                    </button>}
                                    {c.pwd && <button onClick={() => navigator.clipboard.writeText(c.pwd)} type="button" className="ms-1 ms-1 btn btn-primary btn-new-rel btn-sm ">
                                        P
                                    </button>}

                                </div>
                            </div>
                        }
                    }),
                    denomination: TableUtils.composeCell({
                        fieldName: "denomination",
                        fieldValue: c.denomination,
                        onClick: () => openContactModal(c)
                    }),
                    phoneNumbers: TableUtils.composeCell({
                        fieldName: "phoneNumbers",
                        fieldValue: c.contactNumbers.map(el => `${el.phone} - ${el.denomination}`).join(" ; "),
                        component: () => {
                            return c.contactNumbers.map(el => {
                                return <button onClick={() => openContactModalAndAddNewHistory(c, el)}> {el.phone}</button>
                            })
                        }
                    }),
                    callDate: TableUtils.composeCell({
                        fieldName: "callDate",
                        fieldValue: formatDateTimeForDisplay(c?.contactHistory?.date),
                        onClick: () => openContactModal(c)
                    }),
                    caller: TableUtils.composeCell({
                        fieldName: "caller",
                        fieldValue: c?.contactHistory?.caller,
                        onClick: () => openContactModal(c)
                    }),
                    calledContact: TableUtils.composeCell({
                        fieldName: "calledContact",
                        fieldValue: c?.contactHistory?.contact,
                        onClick: () => openContactModal(c)
                    }),

                    callNotes: TableUtils.composeCell({
                        fieldName: "callNotes",
                        fieldValue: c?.contactHistory?.notes,
                        onClick: () => openContactModal(c)
                    }),
                    callPriority: TableUtils.composeCell({
                        fieldName: "callPriority",
                        fieldValue:  c.callPriority,
                        editable:true,
                        onEdit: (rowId, fieldName, value) => onEdit(TableUtils.updateList({ list: customerContacts, rowId, fieldName, value }), c.id)
                    }),
                    save: TableUtils.composeCell({
                        fieldName: "save",
                        component: () => {
                            return <div className='w-100 d-flex justify-content-center'>
                                <button disabled={!editedIds.includes(c.id)} className="btn btn-new-rel btn-sm " onClick={() => { onUpdate(c) }} >Salva</button>
                            </div>
                        }
                    }),
                }

            })
        })
    }

    const onEdit=(newList = [], id)=>{
        let arr = editedIds;
        arr.push(id);
        setEditedIds(arr);
        setRows(prepareRows(newList));
    }

    const onUpdate=(contactInfo)=>{
        saveInlineContactInfo(contactInfo)
        .then((res)=>{
            const ids = editedIds.filter(id => id !== contactInfo.id);
            setEditedIds(ids);
            getCustomerContacts();
        },
        (err)=> {
            PopupError({text: err.title || APPLICATION_GENERIC_ERROR})
        }
    )
    }

    const getCustomerContacts = () => {
        searchContactInfos(searchObject).then(res => {
            setRows(prepareRows(res.content))
        })

    }


    return (
        <React.Fragment>
            <Row className="align-items-center mt-1 mb-1">
                <Col lg={4}  >
                    <BtnFill
                        text={"Aggiungi Cliente"}
                        handlerOnClick={() => openContactModal(baseContact)}
                    ></BtnFill>
                </Col>
                <Col lg={4} className='text-center'>
                    <h2>StarTel</h2>
                </Col>
                <Col lg={2} className='text-center ms-auto'>
                    <BwmSelect
                        className={"form-control"}
                        label={"Chiamante di default"}
                        options={opzioniChiamante}
                        value={chiamante?.id}
                        onChange={e => setChiamante(opzioniChiamante.find(o => opzioniChiamante.id === e.target.value))}
                    />
                </Col>
            </Row>

            <StarTable
                headerColums={headers}
                rows={rows}
                tableConfigurationKey={"adminContactsList"}
                searchObjectPrototype={searchObject}
                setSearchObject={(newSearchObject) => { setSearchObject(newSearchObject) }}
                searchCallBack={searchContactInfos}
                onExecutedSearch={content => {
                    setRows(prepareRows(content));
                }}
            />
            {
                isContactModalOpen &&
                <ContactInfoModal
                    show={isContactModalOpen}
                    contactInfo={selectedContact}
                    onClose={closeContactModal}
                    onSave={() => { closeContactModal(); getCustomerContacts(); }}
                    selectedContactNumber={selectedContactNumber}
                    defaultCaller={chiamante?.description}
                />
            }

        </React.Fragment>

    )
}